<template>
    <transition
        name="menu-overlay"
    >
        <div
            v-show="navigationStore?.isMainMenuOpen"
            ref="focusTarget"
            class="menu-overlay"
            role="dialog"
            :style="{
                '--accent-color': color?.accent
            }"
        >
            <section class="menu-overlay__content">
                <nav
                    v-if="pending || data?.entry?.primaryMenu?.length"
                    class="menu-overlay__items"
                >
                    <h2 class="menu-overlay__title">
                        {{ data?.entry?.primaryMenuTitle || 'Find your ideal stay' }}
                    </h2>

                    <template v-if="pending">
                        <Skeletor
                            v-for="i in [1,2,3,4]"
                            :key="`primary-menu-${i}`"
                            class="menu-overlay__item"
                        />
                    </template>

                    <template v-else>
                        <CraftLink
                            v-for="(link, index) in data?.entry?.primaryMenu"
                            :key="`primary-menu-${index}`"
                            tabindex="0"
                            :link="link.menuLink"
                            class="menu-overlay__item"
                            @mouseenter="activeElement = `link[${index}]`"
                            @mouseleave="activeElement = null"
                            @focus="activeElement = `link[${index}]`"
                            @blur="activeElement = null"
                            @click="navigationStore.closeMainMenu()"
                        >
                            {{ link.menuLink.text }}
                        </CraftLink>
                        <!--SR Controls-->
                        <button
                            class="sr-only"
                            @keydown.enter="navigationStore.closeMainMenu()"
                        >
                            Close main menu
                        </button>
                        <CraftLink
                            link="/"
                            class="sr-only"
                        >
                            Homepage
                        </CraftLink>
                        <!--/SR CONTROLS-->
                    </template>
                </nav>
                <nav
                    v-if="pending || data?.entry?.locationsMenu?.length"
                    class="menu-overlay__locations"
                >
                    <h2 class="menu-overlay__title">
                        {{ data?.entry?.secondaryMenuTitle || 'Cities' }}
                    </h2>
                    <template v-if="pending">
                        <Skeletor
                            v-for="i in [1,2,3,4]"
                            :key="`primary-menu-${i}`"
                            class="menu-overlay__item"
                        />
                    </template>

                    <template v-else>
                        <CraftLink
                            v-for="(link, index) in data?.entry?.locationsMenu"
                            :key="`primary-menu-${index}`"
                            tabindex="0"
                            :link="link.menuLink"
                            class="menu-overlay__item"
                            @mouseenter="activeElement = `location[${index}]`"
                            @mouseleave="activeElement = null"
                            @focus="activeElement = `location[${index}]`"
                            @blur="activeElement = null"
                            @click="navigationStore.closeMainMenu()"
                        >
                            {{ link.menuLink.text }}

                            <span class="menu-overlay__label">
                                {{ link?.label }}
                            </span>
                        </CraftLink>
                    </template>
                </nav>

                <div
                    class="menu-overlay__images"
                    :data-active-element-index="activeElement"
                >
                    <template
                        v-for="(item) in images"
                        :key="`image-${item.group}-${item.index}`"
                    >
                        <BaseImage
                            v-if="item.image?.[0]"
                            :class="{
                                'menu-overlay__image': true,
                                'menu-overlay__image--active': item?.group === 'link' ? (
                                    activeElement === `link[${item.index}]`
                                ) : (
                                    activeElement === `location[${item.index}]`
                                ),
                                [`menu-overlay__image--${item?.group}`]: true
                            }"
                            :data-image-index="item.index"
                            loading="lazy"
                            :image="item.image?.[0]"
                            :fade-in-view="false"
                        />
                    </template>
                </div>

                <footer class="menu-overlay__footer">
                    <ul class="menu-overlay__footer-items">
                        <li
                            v-for="(link, index) in data?.entry?.secondaryMenu"
                            :key="`item-${index}`"
                        >
                            <BaseDotLink :active="false">
                                <CraftLink
                                    :link="link.menuLink"
                                    class="menu-overlay__footer-item"
                                    no-prefetch
                                >
                                    {{ link.menuLink.text }}
                                </CraftLink>
                            </BaseDotLink>
                        </li>
                    </ul>
                    <CallToActionButton v-if="width < 992">
                        <template #cta-text>
                            <i18n-t
                                tag="span"
                                keypath="call-to-action.text"
                                scope="global"
                            >
                                <template #long>
                                    {{ $t('call-to-action.long-text') }}
                                </template>
                            </i18n-t>
                        </template>
                    </CallToActionButton>
                    <ul
                        v-if="data?.generalInformationEntries?.length"
                        class="menu-overlay__footer-contact"
                    >
                        <li v-if="data.generalInformationEntries[0].email">
                            <NuxtLink :to="`mailto:${data.generalInformationEntries[0].email}`">
                                {{ data.generalInformationEntries[0].email}}
                            </NuxtLink>
                        </li>
                        <li v-if="data.generalInformationEntries[0].phoneNumber">
                            <NuxtLink :to="`tel:${ data.generalInformationEntries[0].phoneNumber }`">
                                {{ data.generalInformationEntries[0].phoneNumber }}
                            </NuxtLink>
                        </li>
                    </ul>

                    <TheLanguageSwitcher
                        v-if="width < 992"
                        location="bottom-center"
                    />
                </footer>
            </section>
        </div>
    </transition>
</template>

<script setup>
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { onKeyStroke } from '@vueuse/core';
import MainNavigationQuery from '~/graphql/queries/menuOverlay.graphql';
import { useNavigationStore } from '~/store/navigation';
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

const navigationStore = useNavigationStore();
const route = useRoute();

/** Menu close/open */
const focusTarget = ref();
const { activate, deactivate } = useFocusTrap(focusTarget, { initialFocus: false, allowOutsideClick: true });
watch(() => navigationStore?.isMainMenuOpen, async(value) => {
    if (value) {
        await nextTick();
        activate();
    } else {
        deactivate();
    }
});

watch(() => route.path, () => {
    navigationStore.closeMainMenu();
    activeElement.value = null;
});
onKeyStroke('Escape', () => {
    navigationStore.closeMainMenu();
    activeElement.value = null;
});


/** Navigation */
const { variables } = useCraftGraphql();
delete variables.uri;
const { data, pending } = await useLazyAsyncQuery({
    query: MainNavigationQuery,
    variables
});

// Get hovered element
const activeElement = ref(null);

const images = computed(() => {
    const entry = toValue(data ?? {})?.entry;
    return [
        ...(entry?.primaryMenu ?? []).map((item, index) => ({group: 'link', image: item.image, index})),
        ...(entry?.locationsMenu ?? []).map((item, index) => ({group: 'location', image: item.image, index}))
    ];
});

const color = reactive({
    accent: 'var(--color-pink)'
});

watch(activeElement, (value) => {
    if (value === null){
        color.accent = 'var(--color-pink)';
        return;
    }

    const index = parseInt(value.match(/\d+/)[0], 10);

    switch (index) {
    case 0:
        color.accent = 'var(--color-pink)';
        break;
    case 1:
        color.accent = 'var(--color-pale-blue)';
        break;
    case 2:
        color.accent = 'var(--color-lime)';
        break;
    case 3:
        color.accent = 'var(--color-bright-orange)';
        break;
    }
});
</script>


<style lang="less">
@import './TheMenuOverlay.less';
</style>
