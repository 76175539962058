<template>
    <div
        class="base-dropdown"
        :class="{
            'base-dropdown--open': open
        }"
    >
        <a
            class="base-dropdown__trigger"
            tabindex="0"
            @click="open = true"
            @keydown.enter="open = true"
        >
            <slot name="trigger" />
        </a>

        <transition name="base-dropdown">
            <div
                v-show="open"
                ref="dropdown"
                class="base-dropdown__dropdown"
            >
                <a
                    tabindex="0"
                    class="base-dropdown__close"
                    @click="open = false"
                    @keydown.enter="open = false"
                >
                    <BaseIcon icon="cross" />
                </a>
                <ul>
                    <li
                        v-if="!!$slots.title"
                        class="base-dropdown__title"
                    >
                        <slot name="title" />
                    </li>

                    <li v-if="!!$slots.body">
                        <slot name="body" />
                    </li>

                    <li
                        v-for="item in items"
                        :key="item"
                        class="base-dropdown__item"
                    >
                        <slot
                            :name="`item-${item}`"
                            :active="item === activeItem"
                        />
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script setup>
import BaseIcon from '~/components/BaseIcon.vue';
import { defineProps, ref, toRef, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits(['close']);

const props = defineProps({
    items: {
        type: Array || Number,
        required: false,
        default: () => []
    },
    activeItem: {
        type: [String, Object],
        default: null
    },
    direction: {
        type: String,
        default: 'down',
        required: false,
        validator(value) {
            return ['up', 'down'].includes(value);
        }
    },
    customTrigger: {
        type: Boolean,
        default: null
    }
});

const dropdown = ref(null);
const open = ref(false);

// Close dropdown when clicking outside
onClickOutside(dropdown, () => open.value = false);

import { useDropdownStore } from '@/store/dropdown';
const { openDropdown, closeDropdown } = useDropdownStore();

watch(open, (value) => {
    if (!value) {
        emit('close', value);
        closeDropdown('dropdown');
    } else {
        openDropdown('dropdown');
    }
});

// Close dropdown when active item changes
watch(toRef(props, 'activeItem'), () => {
    open.value = false;
});

watch(toRef(props, 'customTrigger'), (value) => {
    open.value = value;
}, {
    immediate: true
});
</script>

<style lang="less">
.base-dropdown {
    --transition-transform-amount: 100%;
    .typography-text-sm();
    position: relative;
}

.base-dropdown__trigger {
    cursor: pointer;
}

.base-dropdown__dropdown {
    position: absolute;
    background: var(--color-lightest);
    color: var(--color-darkest);

    padding: var(--spacing-md);

    transition: all var(--transition-timing-medium) 0ms var(--transition-timingFunction-in-out);

    [data-direction='down'] & {
        top: 0;
        bottom: auto;
        transform-origin: top center;
    }

    [data-direction-sm='down'] & {
        @media @q-md-max {
            top: 0;
            bottom: auto;
            transform-origin: top center;
        }
    }

    [data-direction='up'] & {
        bottom: 0;
        top: auto;
        transform-origin: bottom center;
    }

    [data-direction-sm='up'] & {
        @media @q-md-max {
            bottom: 0;
            top: auto;
            transform-origin: bottom center;
        }
    }

    ul {
        .list-clean();
    }
}

.base-dropdown__title {
    padding-right: 3rem;
}

.base-dropdown__title,
.base-dropdown__item {
    & + & {
        margin-top: var(--spacing-md);
    }
}

.base-dropdown__close {
    position: absolute;
    font-size: 1.5rem;
    padding: .25rem;
    top: var(--spacing-sm);
    right: var(--spacing-sm);

    cursor: pointer;
    transition: transform var(--transition-timing-fast) 0ms var(--transition-timingFunction-in-out), opacity var(--transition-timing-fast) 0ms var(--transition-timingFunction-in-out);

    &:hover, &:active, &:focus {
        transform: scale(1.1);
        opacity: .5;
    }
}

[data-direction='up'] .base-dropdown-enter-from,
[data-direction='up'] .base-dropdown-leave-to {
    transform: translateY(var(--transition-transform-amount));
}

[data-direction='down'] .base-dropdown-enter-from,
[data-direction='down'] .base-dropdown-leave-to {
    transform: translateY(calc(0% - var(--transition-transform-amount)));
}

[data-direction-sm='up'] .base-dropdown-enter-from,
[data-direction-sm='up'] .base-dropdown-leave-to {
    @media @q-md-max {
        transform: translateY(var(--transition-transform-amount));
    }
}

[data-direction-sm='down'] .base-dropdown-enter-from,
[data-direction-sm='down'] .base-dropdown-leave-to {
    @media @q-md-max {
        transform: translateY(calc(0% - var(--transition-transform-amount)));
    }
}
</style>
