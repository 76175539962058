<template>
    <BaseDropdown
        v-if="!pending && properties?.length"
        :items="properties.map((_, index) => index)"
        :active-item="activeProperty"
        :custom-trigger="shouldDropdownBeOpen"
        class="property-switcher"
        data-direction="down"
    >
        <template #trigger>
            <BaseDotLink>
                <template v-if="activePath">
                    {{ formatShorthand(activeProperty) }}
                </template>
                <template v-else-if="width >= 992">
                    {{ $t('select-location') }}
                </template>
                <template v-else>
                    {{ $t('location') }}
                </template>
            </BaseDotLink>
        </template>

        <template #title>
            {{ $t('select-your-location') }}
        </template>

        <template
            v-for="(property, index) in properties"
            #[`item-${index}`]
            :key="index"
        >
            <CraftLink
                class="property-switcher__link"
                :to="'/' + property.uri"
                @click="handleClickProperty"
            >
                <BaseDotLink
                    :active="isPropertyActive(property)"
                >
                    <DashedText>
                        <template #left>
                            {{ property.city?.[0]?.title }}
                        </template>
                        <template #right>
                            <template v-if="property?.propertyPhase === 'open'">
                                {{ property.area }}
                            </template>
                            <template v-else>
                                {{ $t('coming-soon') }}
                            </template>
                        </template>
                    </DashedText>
                </BaseDotLink>
            </CraftLink>
        </template>
    </BaseDropdown>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';
import propertySwitcherQuery from '~/graphql/queries/propertySwitcher.graphql';
import { useWindowSize } from '@vueuse/core';

const { width } = useWindowSize();

const { variables } = useCraftGraphql();
delete variables.uri;

const route = useRoute();

const response = await useLazyAsyncQuery({
    query: propertySwitcherQuery,
    variables
});

const pending = computed(() => {
    return response.pending.value;
});

const properties = computed(() => {
    const entries = response.data?.value?.entries ?? [];

    return Array.isArray(entries)
        ? entries.filter(entry => entry?.propertyPhase !== 'inDevelopment')
        : [];
});

const activePath = ref(null);
const { locale } = useI18n();

watch(
    () => route.path,
    (path) => {
        if (!locale && !path) {
            return;
        }

        const root = locale.value === 'en' ? '/' : `/${locale.value}/`;

        // check if path starts with '/properties/'
        if (path.startsWith(root + 'properties/')) {
            activePath.value = path.replace(root, '/');
        } else {
            activePath.value = null;
        }
    }, {
        immediate: true
    }
);

const shouldDropdownBeOpen = ref(null);

watch(
    () => route.path,
    () => {
        shouldDropdownBeOpen.value = null;
    }
);

function isPropertyActive(property) {
    if (!activePath.value) {
        return false;
    }

    const matchedProperty = activePath?.value?.match(new RegExp(`^/${property.uri}$`));

    if (matchedProperty?.length) {
        return matchedProperty[0] === activePath?.value;
    } else {
        return activePath?.value?.startsWith(`/${property.uri}/`);
    }
}

const activeProperty = computed(() => {
    const activeProperties = (properties?.value ?? [])?.filter(property => {
        return isPropertyActive(property);
    });

    return activeProperties?.length ? activeProperties[0] : null;
});

function formatShorthand(property) {
    if (!property) {
        return;
    }

    const shortCity = property.city?.[0]?.abbreviation ? property.city?.[0]?.abbreviation : property.city?.[0]?.title?.slice(0, 3);

    return `${shortCity} - ${property.area}`;
}

function handleClickProperty() {
    shouldDropdownBeOpen.value = false;

    // Timeout = hacky way to prevent dropdown from closing when clicking on a property
    setTimeout(() => {
        shouldDropdownBeOpen.value = null;
    }, 100);

    const gtm = useGtm();
    gtm.trackEvent({
        /* eslint-disable camelcase */
        event: 'select_location_click',
        /* eslint-enable camelcase */
    });
}
</script>

<style lang="less">
.property-switcher {
    .base-dropdown__trigger {
        .typography-label();

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media @q-md-max {
            display: none;
        }
    }

    .base-dropdown__dropdown {
        @media @q-md-max {
            right: 0;
        }

        @media @q-md-min {
            width: 35.5vw;
            max-width: 31.875rem;
            left: calc(0px - var(--spacing-md));
        }
    }

    .base-dot-link {
        display: flex;
    }
}

.property-switcher__link {
    .typography-label();
    .link-clean();
    display: block;
    width: 100%;
}

.property-switcher__link--inactive {
    opacity: .5;
}
</style>
